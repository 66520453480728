import React, {  } from 'react'
import { Notice } from "src/assembled"
import { Footer } from 'src/components'
import { SEO } from "src/components/utils"


export default function NoticePage({ location, navigate }) {
    // seo
    const seo = <SEO
    title="Notice"
    description="Introduce News of Deargen." 
    pathname={location.pathname} />


    // render
    const render = <main role="main">
        <Notice location={location} navigate={navigate} />
    </main>


    return <>
        {seo}
        {render}
        <Footer location={location} />
    </>
}